import { Field, Form, Formik } from "formik";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Paginator } from "../../paginator/Paginator";
import { CustomModal } from "../../shared/CustomModal/CustomModal";
import { statoDomain, tipoOperazioneDomain, codStatoListDefault } from "../../shared/domains";
import {
  currencyFormatter,
  getFormikValues,
  getOptionsFromDomain,
  getTipoOperazione,
  handleTextInput,
  isDeletable
} from "../../shared/functions";
import {FormField, FormFilter, RecuperaPartitePrenotateParams, Status} from "../../shared/interface";
import { DettaglioPartitePrenotate } from "../dettaglio/DettaglioPartitePrenotate";
import {
  selectPartitePrenotate,
  getPartitePrenotate,
  putPartitePrenotate,
  selectPagination,
  selectAggiornaStatus
} from "../partitePrenotateSlice";
import { dateFormatter } from "../../shared/functions";
import '../../../index.css'
import { ITEMS_PER_PAGE } from "../../../app/conf";
import { SelectField } from "../../shared/FormikFields/SelectField";
import { UserContext } from "../../../App";
import { MultiSelectField } from "../../shared/FormikFields/MultiSelectField";

export function ListaPartitePrenotate() {
  const dispatch = useAppDispatch();
  const listaPartitePrenotate = useAppSelector(selectPartitePrenotate);
  const paginationItem = useAppSelector(selectPagination);
  const aggiornaPPStatus = useAppSelector(selectAggiornaStatus);
  const [showModal, setShowModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [modalText, setModalText] = useState('');
  const [partitaPrenotata, setPartitaPrenotata] = useState({} as any);
  const [codStato, setCodStato] = useState('');
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(ITEMS_PER_PAGE);
  const [advanceFilter, setAdvanceFilter] = useState(false);
  const [filterText, setFilterText] = useState('Filtri Adv.')
  const user = useContext(UserContext);
  const initialValues: RecuperaPartitePrenotateParams = {
    codCliente: '', nomeCognomeCliente: '', codAgente: '',nomeCognomeAgente: '', 
    numSimula: '', codTipoOperazione: '', codTitolo: '', desTitolo: '', 
    codMandato: '', codStatoList: codStatoListDefault ,page : 0, per_page: 10,
  };
  const [formValues, setFormValues] = useState(initialValues as RecuperaPartitePrenotateParams);

  const formFields: FormFilter = {
    base: [{
      name: 'codAgente',
      placeholder: 'Codice Agente',
      type: 'text',
      className: 'form-control'
    }, {
      name: 'nomeCognomeAgente',
      placeholder: 'Nome e/o Cognome Agente',
      type: 'text',
      className: 'form-control'
    }, {
      name: 'codCliente',
      placeholder: 'Codice Cliente',
      type: 'text',
      className: 'form-control',
      numFill: 9
    }, {
      name: 'nomeCognomeCliente',
      placeholder: 'Nome e/o Cognome Cliente',
      className: 'form-control',
      type: 'text'
    }, {
      name: 'numSimula',
      placeholder: 'Numero Simula',
      className: 'form-control',
      type: 'text'
    }],
    advanced: [{
      name: 'codTipoOperazione',
      placeholder: 'Tipo Operazione',
      type: 'select',
      domain: tipoOperazioneDomain
    }, {
      name: 'codMandato',
      placeholder: 'Codice Mandato',
      className: 'form-control',
      type: 'text',
      numFill: 10
    }, {
      name: 'codTitolo',
      placeholder: 'Codice prodotto',
      className: 'form-control',
      type: 'text'
    }, {
      name: 'desTitolo',
      placeholder: 'Descrizione Prodotto',
      className: 'form-control',
      type: 'text'
    }, {
      name: 'codStatoList',
      placeholder: 'Stato',
      className: 'form-control',
      type: 'multiSelect',
      domain: statoDomain
    }]
  };

  const handleAdvanceFilter = (isAdvanced:boolean) => {
    if (isAdvanced) {
      setFilterText('Filtri Base');
    } else {
      setFilterText('Filtri Adv.');
    }
    setAdvanceFilter(isAdvanced);
  }

  const handleCloseDetail = () => setShowDetailModal(false);
  const handleClose = () => setShowModal(false);
  const handleShow = (e: any, erase: boolean, pp: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (erase) {
      setModalText('Si sta procedendo alla cancellazione di un ordine Accettato ma non ancora eseguito.');
      setCodStato('06');
    } else {
      setModalText('Si sta procedendo alla cancellazione di un ordine Accettato ed in esecuzione/o parzialmente eseguito.');
      setCodStato('05');
    }
    setPartitaPrenotata(pp);
    setShowModal(true);
  };
  const openDetail = (item: any) => {
    setPartitaPrenotata(item);
    setShowDetailModal(true);
  };
  const proceed = () => {
    dispatch(putPartitePrenotate({
      idPartitaPrenotata: partitaPrenotata.idPartitaPrenotata,
      controvaloreRegolatoNew: partitaPrenotata.controvaloreRegolato,
      controvaloreResiduoNew: 0,
      numQuoteResiduo: 0,
      codStato
    }));
    handleClose();
  };

  const paginationChanged = (p: number) => {
    setPage(p);
  };

  const perPageChanged = (perPageNew: number) => {
    setPerPage(perPageNew);
  }

  const handleReset = () => {
    setPage(0);
    setPerPage(ITEMS_PER_PAGE)
    setFormValues(initialValues);
  }
  
  /* for initial, reset, cerca, cambio page, cambio per_page */
  useEffect(() => {
    if(user?.uid){
      const valuesNoArray = {...formValues, } as RecuperaPartitePrenotateParams;
      delete valuesNoArray['codStatoList'];

      dispatch(getPartitePrenotate({ ...getFormikValues(valuesNoArray), codStatoList: formValues.codStatoList ? 
        formValues.codStatoList.map((x:any) => x.value) : undefined, page: page, per_page: perPage}));
    }
  }, [dispatch, user, formValues, page, perPage]);

  //when update
  useEffect(() => {
    if(user?.uid && aggiornaPPStatus === Status.success){
      const valuesNoArray = {...formValues, } as RecuperaPartitePrenotateParams;
      delete valuesNoArray['codStatoList'];

      dispatch(getPartitePrenotate({ ...getFormikValues(valuesNoArray), codStatoList: formValues.codStatoList ?
            formValues.codStatoList.map((x:any) => x.value) : undefined, page: page, per_page: perPage}));
    }
  }, [dispatch, user, formValues, page, perPage, aggiornaPPStatus]);

  const renderField = (field: FormField, i: number, setFieldValue:any) => {
    switch (field.type){
      case 'text':
        return(
            <div key={i} className={"m-2 flex-fill"}>
              {field.numFill != null ?
                <Field id={field.name} name={field.name} placeholder={field.placeholder} className={field.className} onChange={(event: any) => {
                  handleTextInput(event, setFieldValue, field?.numFill!)}}/> :
                <Field id={field.name} name={field.name} placeholder={field.placeholder} className={field.className} />
              }
              </div>
        );
      case 'select':
        return(
            <div key={i} className={"m-2 flex-fill"}>
              <Field id={field.name} component={SelectField} options={getOptionsFromDomain(field.domain)}
                         name={field.name} placeholder={field.placeholder} />
            </div>
        );
      case 'checkbox':
      return (
          <div key={i} className={"m-2 flex-fill"}>
            <div className="d-flex flex-column">
              <label htmlFor={field.name} className="fw-bold mx-2">{field.placeholder}</label>
              <Field id={field.name} name={field.name} className={field.className} type="checkbox" />
            </div>
          </div>
        );
      case 'multiSelect':
        return (
          <div key={i} className={"m-2 flex-fill"}>
            <Field id={field.name} component={MultiSelectField} options={getOptionsFromDomain(field.domain)}
                     name={field.name} placeholder={field.placeholder} />
          </div>
        );
    }
  }

  const renderFilterFields = (filtroFields: any, setFieldValue: any) => {
    let fields: ReactNode[] = [];
    filtroFields.forEach((field:FormField, i:number) => {
      fields.push(renderField(field, i, setFieldValue))
    })
    return fields;
  }

  return (
    <>
      <CustomModal
        show={showDetailModal}
        cancelFnc={handleCloseDetail}
        size="lg"
        title="Dettaglio Partite Prenotate"
        component={<DettaglioPartitePrenotate callback={handleShow} closeModal={handleCloseDetail} partitaPrenotata={partitaPrenotata} />}
        confirmFnc={handleCloseDetail}
      />
      <CustomModal
        show={showModal}
        cancelFnc={handleClose}
        showCancel
        title="Attenzione"
        component={
          <>
            {modalText} <br />
            Attenzione! Cancellando l'operazione si modifica il portafoglio sottoposto all'adeguatezza. <br />
            L'operazione cancellata potrà comunque essere regolata.
          </>
        }
        confirmFnc={proceed}
      />

      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          setFormValues(values);
          setPage(0);
          actions.setSubmitting(false);
        }}>
        {({ values, setFieldValue }) => (
          <Form id="filter-form" noValidate>
            {<>
              <div className="d-flex align-items-center">
                <div className="d-flex flex-grow-1 flex-column">
                  <div className="bg-light p-1 rounded d-flex flex-column flex-fill">
                    <div className="d-flex flex-fill flex-wrap">
                      {renderFilterFields(formFields.base, setFieldValue)}
                    </div>
                    {advanceFilter &&
                        <div className="d-flex flex-fill">
                          {renderFilterFields(formFields.advanced, setFieldValue)}
                        </div>
                    }
                  </div>
                </div>
                <div className="d-flex flex-column mx-2">
                  <div className="m-1">
                    <Button variant="primary" className="btn-ricerca" onClick={() => { handleAdvanceFilter(!advanceFilter) }}>{filterText}</Button>
                  </div>
                  <div className="m-1">
                    <Button variant="primary" className="btn-ricerca" type="submit">Cerca</Button>
                  </div>
                  <div className="m-1">
                    <Button variant="primary" className="btn-ricerca" type="reset" onClick={handleReset}>Reset</Button>
                  </div>
                </div>
              </div>
              </>
            }
          </Form>
        )}
      </Formik>
      {listaPartitePrenotate && listaPartitePrenotate.length > 0 ?
        <>
          <Paginator page={page} perPage={perPage} totalItems={paginationItem.count}
            callback={paginationChanged} callbackPerPage={perPageChanged}></Paginator>
          <Table hover className="mt-1" responsive>
            <thead>
              <tr>
                <th>ID Partita Prenotata</th>
                <th>Codice Cliente</th>
                <th>Nome e Cognome Cliente</th>
                <th>Codice Agente</th>
                <th>Nome e Cognome Agente</th>
                <th>N. Simula</th>
                <th>Tipo Operazione</th>
                <th>Descrizione Prodotto</th>
                <th>Codice Mandato</th>
                <th>Importo Simulato</th>
                <th>Data Accettazione</th>
                <th>Codice Stato</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {listaPartitePrenotate.map((item: any, index: number) =>
                <tr key={index} onClick={() => openDetail(item)} className="clickable">
                  <td>{item.idPartitaPrenotata}</td>
                  <td>{item.codCliente}</td>
                  <td>{item.nomeCognomeCliente}</td>
                  <td>{item.codAgente}</td>
                  <td>{item.nomeCognomeAgente}</td>
                  <td>{item.numSimula}</td>
                  <td>{getTipoOperazione(item)}</td>
                  <td>{item.desTitolo}</td>
                  <td>{item.codMandato}</td>
                  <td>{currencyFormatter(item.controvaloreSimulato)}</td>
                  <td>{dateFormatter(item.dataScadenza)}</td>
                  <td>{statoDomain.get(item.codStato)}</td>
                  <td className="align-middle">
                    <div className="d-flex">
                      <i className={`bi bi-eraser-fill me-3 icon-hover ${(isDeletable(item)) ? '' : 'isNotDeletable'}`}
                        onClick={(e) => {
                          if (isDeletable(item))
                            handleShow(e, true, item);
                        }}></i>
                      <i className={`bi bi-trash-fill icon-hover ${(isDeletable(item)) ? '' : 'isNotDeletable'}`}
                        onClick={(e) => {
                          if (isDeletable(item))
                            handleShow(e, false, item);
                        }}></i>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Paginator page={page} perPage={perPage} totalItems={paginationItem.count}
            callback={paginationChanged} callbackPerPage={perPageChanged}></Paginator>
        </>
        : <div className="d-flex justify-content-center mt-3"><h4>Nessun risultato disponibile.</h4></div>
      }
    </>
  );
}
